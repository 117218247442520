'use client';

import {
  Button,
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'ui';
import { SignInProvider } from '@/contexts/SignInContext';
import { loginUser } from '@/lib/loginUser';
import { signInIDP } from '@/lib/signInIDP';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';
import { LoginUserForm, PiramidLogo } from 'shared-components';
import { SignInWithGoogle } from './SignInWithGoogle';
import { SignInWithMicrosoft } from './SignInWithMicrosoft';

export const Login = () => {
  const [showLocalStrategy, setShowLocalStrategy] = useState(false);
  const router = useRouter();

  return (
    <div className='w-[22.375rem]'>
      <div className='w-10 h-10'>
        <PiramidLogo variant='orange' />
      </div>
      <h1 className='font-semibold text-[2rem] my-6 text-piramid-black tracking-tighter'>
        Inicia sesión en Piramid.
      </h1>
      <p className='text-stone-300 font-semibold mt-6 mb-9 text-[1.5rem] tracking-[-0.03em] leading-[1.875rem]'>
        Automatiza la gestión de reclamos, mantente organizado y colabora con tu
        equipo.
      </p>
      {showLocalStrategy ? (
        <div className='w-78'>
          <LoginUserForm
            signInIDP={(data) => signInIDP(data).then(() => router.push('/'))}
            loginUser={(data) => loginUser(data).then(() => router.push('/'))}
            backToIdpStrategy={() => setShowLocalStrategy(false)}
          />
        </div>
      ) : (
        <>
          <SignInProvider>
            <div className='flex flex-col space-y-2 w-78'>
              <SignInWithGoogle variant='white' small={true} />
              <Accordion type='single' collapsible>
                <AccordionItem key={'more-options'} value={'more-options'}>
                  <AccordionTrigger className='no-underline flex flex-row items-center justify-center w-78 py-2 focus:no-underline pointer-events-none'>
                    <span className='me-1  text-piramid-black text-base_brand font-semibold pointer-events-auto'>
                      Más opciones
                    </span>
                  </AccordionTrigger>
                  <AccordionContent className='flex-col items-center justify-center w-78'>
                    <SignInWithMicrosoft variant='dark' />
                    <Button
                      className='bg-beige text-piramid-black rounded-[.625rem] hover:bg-beige w-full h-11.1 px-4 py-2'
                      onClick={() => setShowLocalStrategy(true)}
                    >
                      <span className='ml-2 font-semibold text-sm'>
                        Continuar con email
                      </span>
                    </Button>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
            <p className='text-[.625rem] w-78 mt-2 leading-3 text-[#B7B7C0]'>
              Al hacer clic en Continuar, reconoce que ha leído y acepta los
              Términos de servicio y la Política de privacidad de Piramid.
            </p>
          </SignInProvider>
          <div className='flex flex-col items-center justify-center text-center !text-2xs mt-5 h-4'></div>
        </>
      )}
    </div>
  );
};
